import React from "react";

import "./LoginPanel.scss";
import { AuthContext, DefaultAuthContext } from "../lib/AuthContext";
import { ImAIgineDBDemo } from "../lib/ImAIgineDB";
import { signIn } from "../lib/gapi";

interface LoginPanelProps {
  setAuthContext: (authContext: AuthContext) => void;
}
export const LoginPanel: React.FC<LoginPanelProps> = ({ setAuthContext }) => {
  function demoMode() {
    setAuthContext({
      userId: "demo",
      userName: "demo",
      db: new ImAIgineDBDemo(),
      signOut: () => setAuthContext(DefaultAuthContext),
    });
  }

  return (
    <div className="LoginPanel">
      <img src="/logo192.png" alt="compAIr" />

      <p>
        <button className="Login" onClick={signIn}>
          Login
        </button>
        <br />
        with your google account
      </p>

      <p>
        or <button onClick={demoMode}>local demo</button>
      </p>
    </div>
  );
};

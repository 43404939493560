import {
  findFilesByName,
  findFilesInFolder,
  getFile,
  getImageURL,
  getPreviewURL,
} from "./gapi";

export interface ImAIgineFileInfo {
  id: string;
  name: string;
}

export enum ImAIgineVariant {
  LR = "LR",
  SR = "SR",
  TI = "TI",
  GX = "GX",
}

export interface ImAIgineDB {
  listImages(): Promise<ImAIgineFileInfo[]>;
  getImageById(id: string): Promise<ImAIgineFileInfo | undefined>;
  getImageVariant(
    file: ImAIgineFileInfo,
    variant: ImAIgineVariant
  ): Promise<ImAIgineFileInfo | undefined>;
  getImageURL(id?: string): string | undefined;
  getImagePreviewURL(id?: string, size?: number): string | undefined;
}

function gfileToFileInfo(f: gapi.client.drive.File): ImAIgineFileInfo {
  return {
    id: f.id as string,
    name: f.name as string,
  };
}

export class ImAIgineDBGoogleDrive implements ImAIgineDB {
  folderIds = {
    imAIgine: "1HT0_4DfTW68a4xQlJCViR7_lg6MAxPk0",
    tests_GeForce_RTX_2080_Ti: "1Jp3nadKDMaHkHg_ang0n7LAMtfsBi-XO",
    tests_GeForce_RTX_2080: "1ux1KqjKvwBT49EFxmL65jfj1zb7HHvEl",
    tests_Gigapixel: "1eELWBo5GRAOlEvZ_nkCPM6grSd6E11Z7",
    tests_lr: "1BNKJSG9xZxuy4HDtIecT2IghranNjVuR",
  };

  async listImages() {
    return (await findFilesInFolder(this.folderIds.tests_lr)).map(
      gfileToFileInfo
    );
  }

  async getImageById(id: string) {
    const file = await getFile(id);
    if (file) return gfileToFileInfo(file);
    return undefined;
  }

  getFolderForVariant(variant: ImAIgineVariant) {
    switch (variant) {
      case ImAIgineVariant.LR:
        return this.folderIds.tests_lr;
      case ImAIgineVariant.SR:
        return this.folderIds.tests_GeForce_RTX_2080;
      case ImAIgineVariant.TI:
        return this.folderIds.tests_GeForce_RTX_2080_Ti;
      case ImAIgineVariant.GX:
        return this.folderIds.tests_Gigapixel;
    }
  }

  async getImageVariant(file: ImAIgineFileInfo, variant: ImAIgineVariant) {
    const alternatives = await findFilesByName(
      file.name,
      this.getFolderForVariant(variant)
    );
    return alternatives
      .filter(
        (f) =>
          !f.name?.endsWith("mask_plot.jpg") && !f.name?.endsWith("mask.jpg") //ignore mask files
      )
      .map(gfileToFileInfo)[0];
  }

  getImageURL(id?: string) {
    return id && getImageURL(id);
  }
  getImagePreviewURL(id?: string) {
    return id && getPreviewURL(id);
  }
}

export class ImAIgineDBDemo implements ImAIgineDB {
  async listImages() {
    return [
      {
        id: "lr-ramsay",
        name: "ramsay",
      },
    ];
  }

  async getImageById(id: string) {
    const [_, name] = id.split("-");
    return {
      id,
      name,
    };
  }

  getIDPrefixForVariant(variant: ImAIgineVariant) {
    switch (variant) {
      case ImAIgineVariant.LR:
        return "lr";
      case ImAIgineVariant.SR:
        return "sr";
      case ImAIgineVariant.TI:
        return "ti";
      case ImAIgineVariant.GX:
        return "gx";
    }
  }

  async getImageVariant(file: ImAIgineFileInfo, variant: ImAIgineVariant) {
    return {
      id: this.getIDPrefixForVariant(variant) + "-" + file.name,
      name: file.name,
    };
  }

  getImageURL(id?: string) {
    return id && `/images/${id}.jpg`;
  }
  getImagePreviewURL(id?: string) {
    return id && `/images/${id}.preview.jpg`;
  }
}

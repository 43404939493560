// Client ID and API key from the Developer Console
let CLIENT_ID =
    '522749290702-biea7pe3s8f5s2oe0b4oreb4mp54lu7l.apps.googleusercontent.com';
var API_KEY = 'AIzaSyAlZ2ODcGsXe_iQTiON0Awn5udttWba3pA';

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES =
    'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.readonly';

export function init(onSignIn: (user: gapi.auth2.GoogleUser|null) => void) {
  if (typeof gapi !== 'object') {
    console.log('waiting for gapi to load...');
    setTimeout(() => {
      init(onSignIn);
    }, 100);
  } else {
    gapi.load('client:auth2', () => {
      gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
          })
          .then(
              function() {
                function handleSignInState(loggedIn: boolean) {
                  if (loggedIn) {
                    onSignIn(gapi.auth2.getAuthInstance().currentUser.get());
                  } else {
                    onSignIn(null);
                  }
                }

                // Listen for sign-in state changes.
                gapi.auth2.getAuthInstance().isSignedIn.listen(
                    handleSignInState);

                // Handle the initial sign-in state.
                handleSignInState(
                    gapi.auth2.getAuthInstance().isSignedIn.get());
              },
              function(error) {
                console.error(JSON.stringify(error, null, 2));
              });
    });
  }
}

export function signIn() {
  return gapi.auth2.getAuthInstance().signIn(
      {ux_mode: 'redirect', redirect_uri: window.location.origin});
}

export function signOut() {
  gapi.auth2.getAuthInstance().signOut();
}

export async function findFilesByName(name: string, parentId?: string) {
  try {
    const response = await gapi.client.drive.files.list({
      pageSize: 1000,
      fields: 'nextPageToken, files(id, name)',
      q: `name contains '${name.split('.')[0]}*' ${
          parentId ? ` and '${parentId}' in parents` : ''}`,
    });
    return response.result.files || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function findFilesInFolder(id: string) {
  try {
    const response = await gapi.client.drive.files.list({
      pageSize: 1000,
      fields: 'nextPageToken, files(id, name)',
      q: `'${
          id}' in parents and mimeType != 'application/vnd.google-apps.folder'`,
    });
    return response.result.files || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getFile(fileId: string) {
  try {
    const response = await gapi.client.drive.files.get({fileId});
    return response.result;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getPreviewURL(id: string, width: number = 128) {
  return `https://drive.google.com/thumbnail?authuser=0&sz=s${width}&id=${id}`;
}

export function getImageURL(id: string) {
  return `https://drive.google.com/uc?export=view&id=${id}`;
}

import React, {
  useContext,
  useState,
  MouseEventHandler,
  useEffect,
  ChangeEventHandler,
  useCallback,
  useRef,
} from "react";
import Debug from "debug";

import html2canvas from "html2canvas";

import "./CompareImage.scss";
import { CompareFiles } from "./FileCompare";
import { LazyImage } from "./LazyImage";
import { AuthContext } from "../lib/AuthContext";
import { SliderHandle } from "./SliderHandle";
import { ImAIgineVariant } from "../lib/ImAIgineDB";
import { useStorageState } from "../lib/storage";

const debug = Debug("CompareImage");

interface CompareImageProps {
  compareFiles: CompareFiles;
}

type RenderingMode = "pixelated" | "crisp-edges" | "auto";
type CompareType = "overlay" | "slider";
type ImageAspectType =
  | "screen"
  | "instagram_square"
  | "instagram_landscape"
  | "instagram_portrait";

export const CompareImage: React.FC<CompareImageProps> = ({ compareFiles }) => {
  const authContext = useContext(AuthContext);
  const [compareType, setCompareType] = useStorageState<CompareType>(
    "compareImage.compareType",
    "overlay"
  );
  const [renderingMode, setRenderingMode] = useStorageState<RenderingMode>(
    "compareImage.renderingMode",
    "pixelated"
  );
  const [imageAspectType, setImageAspectType] = useStorageState<
    ImageAspectType
  >("compareImage.imageAspectType", "screen");
  const [value, setValue] = useStorageState("compareImage.value", 100);
  const [autoSlide, setAutoSlide] = useState(false);
  const [videoSlide, setVideoSlide] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [{ lImageW, lImageH, lId, lError }, setLImageSize] = useState<{
    lImageW: number;
    lImageH: number;
    lId?: string;
    lError?: boolean;
  }>({
    lImageW: 1000000,
    lImageH: 1000000,
  });
  const [{ rImageW, rImageH, rId, rError }, setRImageSize] = useState<{
    rImageW: number;
    rImageH: number;
    rId?: string;
    rError?: boolean;
  }>({
    rImageW: 1000000,
    rImageH: 1000000,
  });

  if (!!lId && lId !== compareFiles.l?.file?.id) {
    setLImageSize({
      lImageW: 1000000,
      lImageH: 1000000,
    });
  }

  if (!!rId && rId !== compareFiles.r?.file?.id) {
    setRImageSize({
      rImageW: 1000000,
      rImageH: 1000000,
    });
  }

  const handleAutoSlideChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setAutoSlide(e.target.checked);
  };

  useEffect(() => {
    let running = true;
    let direction = 1;
    let val = value;

    if (!autoSlide) {
      return;
    }

    function update() {
      val += (direction / 100) * (1 + (val * (100 - val)) / 10);
      if (val >= 100) {
        direction = -1;
        val = 100;
      }
      if (val <= 0) {
        direction = 1;
        val = 0;
      }

      setValue(val);
      if (running) {
        window.requestAnimationFrame(update);
      }
    }

    window.requestAnimationFrame(update);

    return () => {
      running = false;
    };
  }, [autoSlide /*value // intentionally left out*/]);

  const clientWidth = document.documentElement.clientWidth;
  const clientHeight = document.documentElement.clientHeight;

  const imageSizes = {
    screen: { w: clientWidth * 0.9, h: clientHeight * 0.7 },
    instagram_square: { w: 1080, h: 1080 },
    instagram_portrait: { w: 1080, h: 1350 },
    instagram_landscape: { w: 1080, h: 608 },
  };

  const imageW = Math.min(lImageW, rImageW);
  const imageH = Math.min(lImageH, rImageH);

  let displayWidth = imageSizes[imageAspectType].w;
  let viewW = (displayWidth / zoom) * 10;
  let scrollableX = imageW - viewW;

  // adjust display width if too much zoomed out
  if (scrollableX <= 0) {
    scrollableX = 0;
    viewW = imageW;
    displayWidth = (viewW / 10) * zoom;
  }
  if (x < -scrollableX / 2) {
    setX(-scrollableX / 2);
  }
  if (x > scrollableX / 2) {
    setX(scrollableX / 2);
  }

  let displayHeight = imageSizes[imageAspectType].h;
  let viewH = (displayHeight / zoom) * 10;
  let scrollableY = imageH - viewH;

  // adjust display height if too much zoomed out
  if (scrollableY < 0) {
    scrollableY = 0;
    viewH = imageH;
    displayHeight = (viewH / 10) * zoom;
  }
  if (y < -scrollableY / 2) {
    setY(-scrollableY / 2);
  }
  if (y > scrollableY / 2) {
    setY(scrollableY / 2);
  }

  const updateX = useCallback(
    (newX: number) => {
      let maxX = scrollableX / 2;
      if (newX > maxX) {
        newX = maxX;
      }
      if (newX < -maxX) {
        newX = -maxX;
      }
      setX(newX);
    },
    [setX, scrollableX]
  );

  const updateY = useCallback(
    (newY: number) => {
      const maxY = scrollableY / 2;
      if (newY > maxY) {
        newY = maxY;
      }
      if (newY < -maxY) {
        newY = -maxY;
      }
      setY(newY);
    },
    [setY, scrollableY]
  );

  const mouseMove: MouseEventHandler = ({ buttons, movementX, movementY }) => {
    if (buttons === 1) {
      updateX(x - (movementX / zoom) * 10);
      updateY(y - (movementY / zoom) * 10);
    }
  };

  const containerRef: any = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const touchStart = (e: TouchEvent) => {
      const touch = e.touches[0];
      const touchStartX = touch.pageX;
      const touchStartY = touch.pageY;
      const container = containerRef.current;

      const touchEnd = (e: TouchEvent) => {
        const container = containerRef.current;
        container.removeEventListener("touchmove", touchMove, {
          passive: false,
        });
        container.removeEventListener("touchend", touchEnd, { passive: false });
      };
      const touchMove = (e: TouchEvent) => {
        const touch = e.touches[0];
        const touchX = touch.pageX;
        const touchY = touch.pageY;
        const movementX = touchX - touchStartX;
        const movementY = touchY - touchStartY;
        updateX(x - (movementX / zoom) * 10);
        updateY(y - (movementY / zoom) * 10);

        e.preventDefault();
      };

      container.addEventListener("touchmove", touchMove, { passive: false });
      container.addEventListener("touchend", touchEnd, { passive: false });

      e.preventDefault();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("touchstart", touchStart, { passive: false });
    }
    return () => {
      if (container) {
        container.removeEventListener("touchstart", touchStart, {
          passive: false,
        });
      }
    };
  }, [containerRef, updateX, updateY, x, y, zoom]);

  const onLError = useCallback(() => {
    setLImageSize({
      lImageW: 1000000,
      lImageH: 1000000,
      lError: true,
    });
  }, []);

  const onLReady = useCallback(
    (img: HTMLImageElement) => {
      const newImageW = img.width * (compareFiles.l?.scale ?? 1);
      const newImageH = img.height * (compareFiles.l?.scale ?? 1);

      const expectedUrl = authContext.db?.getImageURL(
        compareFiles.l?.file?.id as string
      );

      if (!img.src || !img.src.endsWith(expectedUrl ?? "")) {
        debug(`WARN: got onload for ${img.src}, but expected ${expectedUrl}`);
        return;
      }

      if (
        newImageW !== lImageW ||
        newImageH !== lImageH ||
        lId !== compareFiles.l?.file?.id
      ) {
        setLImageSize({
          lImageW: newImageW,
          lImageH: newImageH,
          lId: compareFiles.l?.file?.id,
        });
      }

      debug("lImageSize: %d %d", newImageW, newImageH);
    },
    [compareFiles.l, authContext.db, lImageW, lImageH, lId]
  );

  const onRError = useCallback(() => {
    setRImageSize({
      rImageW: 1000000,
      rImageH: 1000000,
      rError: true,
    });
  }, []);

  const onRRead = useCallback(
    (img: HTMLImageElement) => {
      const newImageW = img.width * (compareFiles.r?.scale ?? 1);
      const newImageH = img.height * (compareFiles.r?.scale ?? 1);

      const expectedUrl = authContext.db?.getImageURL(
        compareFiles.r?.file?.id as string
      );

      if (!img.src || !img.src.endsWith(expectedUrl ?? "")) {
        debug(`WARN: got onload for ${img.src}, but expected ${expectedUrl}`);
        return;
      }

      if (
        newImageW !== rImageW ||
        newImageH !== rImageH ||
        rId !== compareFiles.r?.file?.id
      ) {
        setRImageSize({
          rImageW: newImageW,
          rImageH: newImageH,
          rId: compareFiles.r?.file?.id,
        });
      }

      debug("rImageSize: %d %d", newImageW, newImageH);
    },
    [compareFiles.r, authContext.db, rImageW, rImageH, rId]
  );

  let clipPathMin = x + scrollableX / 2;
  if (clipPathMin < 0) {
    clipPathMin = 0;
  }
  let clipPathMax = imageW + (x - scrollableX / 2);
  if (clipPathMax > imageW) {
    clipPathMax = imageW;
  }
  let clipPathOffset = 0;
  if (rImageW > lImageW) {
    clipPathOffset = (rImageW - lImageW) / 2;
  }

  const loaded =
    lId === compareFiles.l?.file?.id && rId === compareFiles.r?.file?.id;

  return (
    <>
      <div className={`CompareImage ${renderingMode}`}>
        <div>
          {lImageW < 1000000 && lImageH < 1000000 ? (
            <span>
              {lImageW / (compareFiles.l?.scale ?? 1)}x
              {lImageH / (compareFiles.l?.scale ?? 1)}
            </span>
          ) : (
            <span>n/a</span>
          )}
          {" vs "}
          {rImageW < 1000000 && rImageH < 1000000 ? (
            <span>
              {rImageW / (compareFiles.r?.scale ?? 1)}x
              {rImageH / (compareFiles.r?.scale ?? 1)}
            </span>
          ) : (
            <span>n/a</span>
          )}
        </div>
        <table className="SettingsTable">
          <tbody>
            <tr>
              <th>Rendering Mode</th>
              <td>
                <button
                  className={
                    renderingMode === "crisp-edges" ? "active" : "inactive"
                  }
                  onClick={() => setRenderingMode("crisp-edges")}
                >
                  crisp
                </button>
                <button
                  className={renderingMode === "auto" ? "active" : "inactive"}
                  onClick={() => setRenderingMode("auto")}
                >
                  smooth
                </button>
                <button
                  className={
                    renderingMode === "pixelated" ? "active" : "inactive"
                  }
                  onClick={() => setRenderingMode("pixelated")}
                >
                  pixelated
                </button>
              </td>
            </tr>
            <tr>
              <th>ImageSize</th>
              <td>
                <button
                  className={
                    imageAspectType === "screen" ? "active" : "inactive"
                  }
                  onClick={() => setImageAspectType("screen")}
                >
                  screen
                </button>
                <button
                  className={
                    imageAspectType === "instagram_square"
                      ? "active"
                      : "inactive"
                  }
                  onClick={() => setImageAspectType("instagram_square")}
                >
                  instagram square
                </button>
                <button
                  className={
                    imageAspectType === "instagram_portrait"
                      ? "active"
                      : "inactive"
                  }
                  onClick={() => setImageAspectType("instagram_portrait")}
                >
                  instagram portrait
                </button>
                <button
                  className={
                    imageAspectType === "instagram_landscape"
                      ? "active"
                      : "inactive"
                  }
                  onClick={() => setImageAspectType("instagram_landscape")}
                >
                  instagram landscape
                </button>
              </td>
            </tr>
            <tr>
              <th>
                {displayWidth < imageSizes[imageAspectType].w ||
                displayHeight < imageSizes[imageAspectType].h ? (
                  "too small"
                ) : loaded ? (
                  <button
                    onClick={async () => {
                      const snapshotArea = document.getElementById(
                        "SnapshotArea"
                      );
                      if (snapshotArea) {
                        snapshotArea.innerHTML = "";
                        const lCanvas = document.createElement("canvas");
                        const mCanvas = document.createElement("canvas");
                        const rCanvas = document.createElement("canvas");
                        lCanvas.width = displayWidth;
                        lCanvas.height = displayHeight;
                        const lCtx = lCanvas.getContext("2d");
                        mCanvas.width = displayWidth;
                        mCanvas.height = displayHeight;
                        const mCtx = mCanvas.getContext("2d");
                        rCanvas.width = displayWidth;
                        rCanvas.height = displayHeight;
                        const rCtx = rCanvas.getContext("2d");

                        if (lCtx && mCtx && rCtx) {
                          lCtx.imageSmoothingEnabled =
                            renderingMode === "pixelated" &&
                            compareFiles.l?.variant === ImAIgineVariant.LR
                              ? false
                              : true;
                          lCtx.imageSmoothingQuality = "high";
                          mCtx.imageSmoothingEnabled =
                            renderingMode === "pixelated" &&
                            compareFiles.l?.variant === ImAIgineVariant.LR
                              ? false
                              : true;
                          mCtx.imageSmoothingQuality = "high";
                          rCtx.imageSmoothingEnabled =
                            renderingMode === "pixelated" &&
                            compareFiles.r?.variant === ImAIgineVariant.LR
                              ? false
                              : true;
                          rCtx.imageSmoothingQuality = "high";

                          const lImg = new Image();
                          lImg.src = authContext.db?.getImageURL(
                            compareFiles.l?.file?.id
                          ) as string;

                          const lOffsetX =
                            lImageW > rImageW ? (lImageW - rImageW) / 2 : 0;
                          const lOffsetY =
                            lImageH > rImageH ? (lImageH - rImageH) / 2 : 0;
                          lImg.onload = () => {
                            lCtx.drawImage(
                              lImg,
                              (lOffsetX + scrollableX / 2 + x) /
                                (compareFiles.l?.scale ?? 1),
                              (lOffsetY + scrollableY / 2 + y) /
                                (compareFiles.l?.scale ?? 1),
                              viewW / (compareFiles.l?.scale ?? 1),
                              viewH / (compareFiles.l?.scale ?? 1),
                              0,
                              0,
                              displayWidth,
                              displayHeight
                            );
                            mCtx.drawImage(
                              lImg,
                              (lOffsetX + scrollableX / 2 + x) /
                                (compareFiles.l?.scale ?? 1),
                              (lOffsetY + scrollableY / 2 + y) /
                                (compareFiles.l?.scale ?? 1),
                              viewW / (compareFiles.l?.scale ?? 1),
                              viewH / (compareFiles.l?.scale ?? 1),
                              0,
                              0,
                              displayWidth,
                              displayHeight
                            );

                            // save lr version
                            snapshotArea.appendChild(lCanvas);

                            const rOffsetX =
                              rImageW > lImageW ? (rImageW - lImageW) / 2 : 0;
                            const rOffsetY =
                              rImageH > lImageH ? (rImageH - lImageH) / 2 : 0;

                            const rImg = new Image();
                            rImg.src = authContext.db?.getImageURL(
                              compareFiles.r?.file?.id
                            ) as string;
                            rImg.onload = async () => {
                              rCtx.drawImage(
                                rImg,
                                (rOffsetX + scrollableX / 2 + x) /
                                  (compareFiles.r?.scale ?? 1),
                                (rOffsetY + scrollableY / 2 + y) /
                                  (compareFiles.r?.scale ?? 1),
                                viewW / (compareFiles.r?.scale ?? 1),
                                viewH / (compareFiles.r?.scale ?? 1),
                                0,
                                0,
                                displayWidth,
                                displayHeight
                              );

                              mCtx.imageSmoothingEnabled =
                                renderingMode === "pixelated" &&
                                compareFiles.r?.variant === ImAIgineVariant.LR
                                  ? false
                                  : true;

                              if (compareType === "overlay") {
                                mCtx.globalAlpha = value / 100;

                                mCtx.drawImage(
                                  rImg,
                                  (rOffsetX + scrollableX / 2 + x) /
                                    (compareFiles.r?.scale ?? 1),
                                  (rOffsetY + scrollableY / 2 + y) /
                                    (compareFiles.r?.scale ?? 1),
                                  viewW / (compareFiles.r?.scale ?? 1),
                                  viewH / (compareFiles.r?.scale ?? 1),
                                  0,
                                  0,
                                  displayWidth,
                                  displayHeight
                                );
                              }

                              if (compareType === "slider") {
                                const sliderHandleCanvas = await html2canvas(
                                  document.getElementById(
                                    "captureSlider"
                                  ) as HTMLElement,
                                  {
                                    backgroundColor: "transparent",
                                    scale: 1,
                                    width: 50,
                                    x: -25,
                                    y: 0,
                                    scrollX: 0,
                                    scrollY: 0,
                                    height: displayHeight,
                                  }
                                );
                                mCtx.drawImage(
                                  rImg,
                                  (rOffsetX +
                                    scrollableX / 2 +
                                    x +
                                    (viewW * value) / 100) /
                                    (compareFiles.r?.scale ?? 1),
                                  (rOffsetY + scrollableY / 2 + y) /
                                    (compareFiles.r?.scale ?? 1),
                                  (viewW * (1 - value / 100)) /
                                    (compareFiles.r?.scale ?? 1),
                                  viewH / (compareFiles.r?.scale ?? 1),
                                  (displayWidth * value) / 100,
                                  0,
                                  displayWidth * (1 - value / 100),
                                  displayHeight
                                );
                                mCtx.imageSmoothingEnabled = true;
                                mCtx.drawImage(
                                  sliderHandleCanvas,
                                  (value / 100) * displayWidth -
                                    sliderHandleCanvas.width / 2,
                                  0
                                );
                              }

                              // save mixed version
                              snapshotArea.appendChild(mCanvas);

                              // save sr version
                              snapshotArea.appendChild(rCanvas);
                            };
                          };
                        }
                      }
                    }}
                  >
                    snapshot
                  </button>
                ) : (
                  "loading"
                )}
              </th>
              <td>
                <div id="SnapshotArea" className="SnapshotArea" />
              </td>
            </tr>
            <tr>
              <th>
                <span style={{ display: "inline-block", width: "2em" }}>
                  {Math.round(zoom) / 10}
                </span>
                {"x "}
                Zoom
              </th>
              <td>
                <div className="ZoomSliderContainer">
                  <input
                    id="zoom-slider"
                    type="range"
                    min="1"
                    max="40"
                    value={zoom}
                    onChange={(e) => {
                      setZoom(Number.parseInt(e.target.value));
                    }}
                  />
                  <button
                    onClick={() => {
                      setZoom(10);
                    }}
                  >
                    reset
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <input
                  type="radio"
                  name="compare-type-radio"
                  id="compare-type-radio-slider"
                  checked={compareType === "slider"}
                  onChange={(e) => e.target.checked && setCompareType("slider")}
                />
                <label htmlFor="compare-type-radio-slider">slider</label>
                <input
                  type="radio"
                  name="compare-type-radio"
                  id="compare-type-radio-overlay"
                  checked={compareType === "overlay"}
                  onChange={(e) =>
                    e.target.checked && setCompareType("overlay")
                  }
                />
                <label htmlFor="compare-type-radio-overlay">overlay</label>
              </th>
              <td>
                <div className="ValueSliderContainer">
                  <input
                    id="value-slider"
                    type="range"
                    min="0"
                    max="100"
                    value={value}
                    onChange={(e) => {
                      setValue(Number.parseInt(e.target.value));
                    }}
                  />
                  <span
                    style={{
                      display: "inline-block",
                      padding: "0em 0.2em",
                      width: "2em",
                      minWidth: "2em",
                    }}
                  >
                    {Math.round(value * 10) / 10}
                  </span>

                  <input
                    id="autoslide-checkbox"
                    type="checkbox"
                    onChange={handleAutoSlideChange}
                    checked={autoSlide}
                  />
                  <label htmlFor="autoslide-checkbox">autoslide&nbsp;</label>
                  <button
                    disabled={videoSlide}
                    onClick={() => {
                      let val = value > 50 ? 100 : 0;
                      let direction = val > 50 ? -1 : 1;

                      setVideoSlide(true);
                      if (autoSlide) {
                        setAutoSlide(false);
                      }
                      if (value !== val) {
                        setValue(val);
                      }

                      let step = 0;

                      /*
    Modes:
    * inOut
    * Nach dem klicken 2 sek warten, dann sliden - 1 sek warten - zurück sliden - 1 sek warten - nochmal sliden und dann 3 sek warten
    * vielleicht auch kurz in der Mitte verweilen oder langsamer werden?
    */

                      setTimeout(() => {
                        let zoomStart = zoom;
                        let zoomCurrent = zoom;
                        function zoomIn() {
                          const zoomSpeed =
                            (zoomCurrent < 10 ? 1 : -1) *
                            (0.01 + Math.abs(10 - zoomCurrent) / 10) *
                            (0.01 +
                              Math.abs(zoomStart - zoomCurrent) /
                                (1 + Math.abs(zoomStart - 10)));
                          zoomCurrent += zoomSpeed;

                          if (Math.abs(zoomCurrent - 10) < 0.01) {
                            zoomCurrent = 10;
                          }

                          setZoom(zoomCurrent);

                          if (zoomCurrent === 10) {
                            setTimeout(update, 200);
                          } else {
                            window.requestAnimationFrame(zoomIn);
                          }
                        }
                        function update() {
                          val +=
                            (direction / 100) * (1 + (val * (100 - val)) / 10);
                          if (val >= 100) {
                            direction = -1;
                            val = 100;

                            step++;
                            if (step < 3) {
                              setTimeout(update, 1000);
                            } else {
                              setVideoSlide(false);
                            }
                          } else if (val <= 0) {
                            direction = 1;
                            val = 0;
                            step++;
                            if (step < 3) {
                              setTimeout(update, 1000);
                            } else {
                              setVideoSlide(false);
                            }
                          } else {
                            window.requestAnimationFrame(update);
                          }
                          setValue(val);
                        }

                        window.requestAnimationFrame(zoomIn);
                      }, 2000);
                    }}
                  >
                    video&nbsp;slide
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {imageH === 1000000 && " loading images..."}
        <p></p>
        <div
          id="OverlayContainer"
          className={`OverlayContainer ${loaded ? "loaded" : "loading"}`}
          ref={containerRef}
          onMouseMove={mouseMove}
          style={{ width: displayWidth, height: displayHeight }}
        >
          {lError && (
            <div className="error">
              failed to load{" "}
              <a href={authContext.db?.getImageURL(compareFiles.l?.file?.id)}>
                {compareFiles.l?.file?.name}
              </a>
            </div>
          )}
          {rError && (
            <div className="error">
              failed to load{" "}
              <a href={authContext.db?.getImageURL(compareFiles.r?.file?.id)}>
                {compareFiles.r?.file?.name}
              </a>
            </div>
          )}
          <div
            className="ZoomLayer"
            style={{
              transform: `scale(${zoom / 10})`,
              transformOrigin: "center center",
            }}
          >
            <div
              className="PanLayer"
              style={{
                transform: `translate(${
                  -x - imageW / 2 + displayWidth / 2
                }px, ${-y - imageH / 2 + displayHeight / 2}px)`,
              }}
            >
              <LazyImage
                style={{
                  transformOrigin: "top left",
                  transform: `scale(${compareFiles.l?.scale ?? 1}) translate(${
                    lImageW > rImageW
                      ? -(lImageW - rImageW) / 2 / (compareFiles.l?.scale ?? 1)
                      : 0
                  }px,${
                    lImageH > rImageH
                      ? -(lImageH - rImageH) / 2 / (compareFiles.l?.scale ?? 1)
                      : 0
                  }px)`,
                }}
                alt="lImg"
                src={authContext.db?.getImageURL(compareFiles.l?.file?.id)}
                onLoad={onLReady}
                onError={onLError}
              />
              <LazyImage
                style={{
                  transformOrigin: "top left",
                  transform: `scale(${compareFiles.r?.scale ?? 1}) translate(${
                    rImageW > lImageW
                      ? -(rImageW - lImageW) / 2 / (compareFiles.r?.scale ?? 1)
                      : 0
                  }px,${
                    rImageH > lImageH
                      ? -(rImageH - lImageH) / 2 / (compareFiles.r?.scale ?? 1)
                      : 0
                  }px)`,
                  ...(compareType === "slider"
                    ? {
                        clipPath: `inset(0px 0px 0px ${
                          (clipPathMin * (1 - value / 100) +
                            (clipPathMax * value) / 100 +
                            clipPathOffset) /
                          (compareFiles.r?.scale ?? 1)
                        }px)`,
                      } /* slider */
                    : { opacity: value / 100 }) /* overlay */,
                }}
                alt="rImg"
                src={authContext.db?.getImageURL(compareFiles.r?.file?.id)}
                onLoad={onRRead}
                onError={onRError}
              />
            </div>
          </div>
          {compareType === "slider" && (
            <SliderHandle
              sliderPosition={value / 100}
              moveSliderPosition={(increment: number) => {
                let newValue =
                  value + increment / (window?.devicePixelRatio ?? 1);
                if (newValue < 0) {
                  newValue = 0;
                }
                if (newValue > 100) {
                  newValue = 100;
                }
                setValue(newValue);
              }}
              containerWidth={displayWidth}
              containerHeight={displayHeight}
            />
          )}
        </div>
      </div>
      <div style={{ opacity: 0 }}>
        <SliderHandle
          id="captureSlider"
          sliderPosition={0}
          moveSliderPosition={() => {}}
          containerWidth={displayWidth}
          containerHeight={displayHeight}
        />
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import "./App.css";
import { init, signOut } from "./lib/gapi";
import { FileList } from "./components/FileList";
import { ImAIgineDBGoogleDrive } from "./lib/ImAIgineDB";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FileCompare } from "./components/FileCompare";
import { AuthContext, DefaultAuthContext } from "./lib/AuthContext";
import { Header } from "./components/Header";
import { LoginPanel } from "./components/LoginPanel";

function App() {
  const [authContext, setAuthContext] = useState<AuthContext>(
    DefaultAuthContext
  );

  useEffect(() => {
    init(async (user: gapi.auth2.GoogleUser | null) => {
      if (!user) {
        setAuthContext(DefaultAuthContext);
      } else {
        const profile = user.getBasicProfile();
        console.log(`is signed in ${user.getBasicProfile()?.getName()}`);
        const db = new ImAIgineDBGoogleDrive();
        setAuthContext({
          userName: profile?.getName(),
          userId: profile?.getId(),
          db,
          signOut: signOut,
        });
      }
    });
  }, [setAuthContext]);

  return (
    <AuthContext.Provider value={authContext}>
      {!!authContext.userId ? (
        !!authContext.db ? (
          <Router>
            <Header />
            <Switch>
              <Route exact path="/">
                <FileList />
              </Route>
              <Route path="/compare/:id" children={<FileCompare />} />
            </Switch>
          </Router>
        ) : (
          <>Sorry, you do not have access</>
        )
      ) : (
        <LoginPanel setAuthContext={setAuthContext} />
      )}
    </AuthContext.Provider>
  );
}

export default App;

import {useCallback, useState} from 'react';


class FakeStorage implements Storage {
  private storage: {[key: string]: any} = {};

  getItem(key: string) {
    return this.storage[key] || null
  }

  setItem(key: string, value: any) {
    this.storage[key] = value
  }

  removeItem(key: string) {
    delete this.storage[key]
  }

  key(n: number) {
    return Object.keys(storage)[n]
  }

  clear() {
    this.storage = {}
  }

  get length() {
    return Object.keys(storage).length
  }
}

const storage: Storage =
    (window !== undefined && window.localStorage) || new FakeStorage();

export function getStorageValue<T>(key: string, fallback: T): T {
  const val = storage.getItem(key)
  if (val !== null) {
    return JSON.parse(val);
  }
  return fallback
}

export function setStorageValue<T>(key: string, value: T) {
  storage.setItem(key, JSON.stringify(value))
}


type SetStateAction<S> = S|((prevState: S) => S);
type Dispatch<A> = (value: A) => void;

export function useStorageState<S>(
    key: string, initialState: S|(() => S)): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(getStorageValue(key, initialState))

  const setStorageState: Dispatch<SetStateAction<S>> =
      useCallback((updateState: SetStateAction<S>) => {
        const newState = typeof updateState === 'function' ?
            (updateState as any)(state) :
            updateState;
        setStorageValue(key, newState);
        setState(newState)
      }, [key, state, setState])

  return [state, setStorageState]
}
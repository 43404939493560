import React, { useEffect, useState, useContext } from "react";
import { PreviewImage } from "./PreviewImage";

import "./FileList.scss";
import { AuthContext } from "../lib/AuthContext";
import { ImAIgineFileInfo } from "../lib/ImAIgineDB";

export const FileList: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [contents, setContents] = useState<ImAIgineFileInfo[] | null>(null);

  useEffect(() => {
    authContext?.db?.listImages().then((contents) => setContents(contents));
  }, [authContext]);
  return (
    <>
      <div className="FileList">
        {!!contents ? (
          contents.map((f) => (
            <PreviewImage
              key={f.id}
              name={f.name as string}
              id={f.id as string}
            />
          ))
        ) : (
          <>searching...</>
        )}
      </div>
    </>
  );
};

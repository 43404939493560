import React, { useContext } from "react";
import { AuthContext } from "../lib/AuthContext";
import { Link } from "react-router-dom";

import "./Header.scss";

export const Header: React.FC = () => {
  const authContext = useContext(AuthContext);
  return (
    <div className="Header">
      <Link className="LogoArea" to="/">
        <img src="/logo192.png" alt="compAIr" />
        compAIr
      </Link>
      <div className="UserInfo">
        {authContext.userName}{" "}
        <button onClick={authContext.signOut}>SignOut</button>
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./PreviewImage.scss";
import { LazyImage } from "./LazyImage";
import { AuthContext } from "../lib/AuthContext";

interface PreviewImageProps {
  id: string;
  name: string;
}

export const PreviewImage: React.FC<PreviewImageProps> = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  return (
    <div className="PreviewImage">
      <Link className={`ImageLink`} to={`/compare/${id}`}>
        <div className="ImageHolder">
          <LazyImage alt={name} src={authContext.db?.getImagePreviewURL(id)} />
        </div>
        <div className="Label">{name}</div>
      </Link>
    </div>
  );
};

import React, { useEffect, useState, CSSProperties } from "react";
import Debug from "debug";

import "./LazyImage.scss";

interface LazyImageProps {
  src?: string;
  alt: string;
  onLoad?: (img: HTMLImageElement) => void;
  onError?: () => void;
  className?: string;
  style?: CSSProperties;
}

const debug = Debug("LazyImage");
export const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  onLoad,
  onError,
  className,
  style,
}) => {
  const [img, setImg] = useState<HTMLImageElement | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (src) {
      const image = new Image();
      debug(`loading ${src}`);
      if (error) {
        setError(false);
      }
      image.src = src;
      image.onerror = (e) => {
        debug(`error`, e);
        setError(true);
        onError && onError();
      };
      image.onload = () => {
        setTimeout(() => {
          setImg(image);
          debug(`ready ${src}`);
          onLoad && onLoad(image);
        }, 100);
      };
    }
  }, [setImg, src, onLoad, onError]);

  return !error && img && img.src && img.src.endsWith(src ?? "") ? (
    <img
      className={`LazyImage  ${className ?? ""}`}
      style={style}
      alt={alt}
      src={img.src}
    />
  ) : error ? (
    <span className={`LazyImage ${className ?? ""} error`} style={style}>
      error loading <a href={src}>{alt}</a>
    </span>
  ) : (
    <span className={`LazyImage ${className ?? ""} loading`} style={style}>
      loading {alt}...
    </span>
  );
};

import React from "react";

interface SliderHandleProps {
  id?: string;
  sliderPosition: number;
  moveSliderPosition: (increment: number) => void;
  containerWidth: number;
  containerHeight: number;
}

export const SliderHandle: React.FC<SliderHandleProps> = ({
  id,
  sliderPosition,
  moveSliderPosition,
  containerHeight,
  containerWidth,
}) => {
  const horizontal = true;
  const handleSize = 50;
  const sliderLineColor = "#ffffff";
  const sliderLineWidth = 5;

  return (
    <div
      id={id}
      className={"SliderHandle"}
      style={{
        alignItems: "center",
        // cursor:  horizontal ? "ew-resize" : "ns-resize",
        display: "flex",
        flexDirection: horizontal ? "column" : "row",
        height: horizontal ? `${containerHeight}px` : `${handleSize}px`,
        justifyContent: "center",
        left: horizontal
          ? `${containerWidth * sliderPosition - handleSize / 2}px`
          : 0,
        position: "absolute",
        top: horizontal
          ? 0
          : `${containerHeight * sliderPosition - handleSize / 2}px`,
        width: horizontal ? `${handleSize}px` : `${containerWidth}px`,
      }}
    >
      <div
        style={{
          background: sliderLineColor,
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
          flex: "0 1 auto",
          height: horizontal ? "100%" : `${sliderLineWidth}px`,
          width: horizontal ? `${sliderLineWidth}px` : "100%",
        }}
      />

      <div
        onTouchStart={(e) => {
          document.body.style.pointerEvents = "none";

          const options = {
            capture: true,
            passive: false,
          };

          let val = sliderPosition;

          let x = -1;

          function moveListener(e: TouchEvent) {
            if (x < 0) x = e.touches[0].clientX;

            const movementX =
              (e.touches[0].clientX - x) * window.devicePixelRatio;

            x = e.touches[0].clientX;

            val += (movementX * 100) / containerWidth;
            moveSliderPosition(val);

            e.preventDefault();
            e.stopPropagation();
          }

          function touchEndListener() {
            document.removeEventListener("touchmove", moveListener, options);
            document.removeEventListener("touchend", touchEndListener, options);
            document.body.style.pointerEvents = "auto";
          }
          document.addEventListener("touchmove", moveListener, options);
          document.addEventListener("touchend", touchEndListener, options);
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          document.body.style.pointerEvents = "none";

          const options = {
            capture: true,
          };

          let val = sliderPosition;
          function moveListener(e: MouseEvent) {
            const { buttons, movementX } = e;
            if (buttons !== 1) {
              mouseUpListener();
            }
            val += (movementX * 100) / containerWidth;
            moveSliderPosition(val);
          }

          function mouseUpListener() {
            document.removeEventListener("mousemove", moveListener, options);
            document.removeEventListener("mouseup", mouseUpListener, options);
            document.body.style.pointerEvents = "auto";
          }
          document.addEventListener("mousemove", moveListener, options);
          document.addEventListener("mouseup", mouseUpListener, options);
        }}
        style={{
          alignItems: "center",
          border: `${sliderLineWidth}px solid ${sliderLineColor}`,
          borderRadius: "100%",
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
          boxSizing: "border-box",
          display: "flex",
          flex: "1 0 auto",
          height: `${handleSize}px`,
          justifyContent: "center",
          width: `${handleSize}px`,
          transform: horizontal ? "none" : "rotate(90deg)",
        }}
      >
        <div
          style={{
            border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
            borderRight: `${handleSize * 0.15}px solid ${sliderLineColor}`,
            height: "0px",
            marginLeft: `-${handleSize * 0.25}px`, // for IE11
            marginRight: `${handleSize * 0.25}px`,
            width: "0px",
          }}
        />
        <div
          style={{
            border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
            borderLeft: `${handleSize * 0.15}px solid ${sliderLineColor}`,
            height: "0px",
            marginRight: `-${handleSize * 0.25}px`, // for IE11
            width: "0px",
          }}
        />
      </div>

      <div
        style={{
          background: sliderLineColor,
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
          flex: "0 1 auto",
          height: horizontal ? "100%" : `${sliderLineWidth}px`,
          width: horizontal ? `${sliderLineWidth}px` : "100%",
        }}
      />
    </div>
  );
};

import { createContext } from "react";
import { ImAIgineDB } from "./ImAIgineDB";

export interface AuthContext {
  userId?: string;
  userName?: string;
  db?: ImAIgineDB;
  signOut: () => void;
}

export const DefaultAuthContext = {
  signOut: () => {},
};

export const AuthContext = createContext<AuthContext>(DefaultAuthContext);

import React, { useEffect, useState, useContext, CSSProperties } from "react";

import { useParams } from "react-router-dom";

import "./FileCompare.scss";
import { AuthContext } from "../lib/AuthContext";
import { ImAIgineFileInfo, ImAIgineVariant } from "../lib/ImAIgineDB";
import { LazyImage } from "./LazyImage";
import { CompareImage } from "./CompareImage";
import { getStorageValue, useStorageState } from "../lib/storage";

interface CompareFileInfo {
  file?: ImAIgineFileInfo;
  scale?: number;
  variant: ImAIgineVariant;
}
export interface CompareFiles {
  name?: string;
  l?: CompareFileInfo; //HTMLImageElement; //gapi.client.drive.File;
  r?: CompareFileInfo; //HTMLImageElement; //gapi.client.drive.File;
}

export interface VariantSelectorProps {
  style?: CSSProperties;
  variant: ImAIgineVariant;
  setVariant: (variant: ImAIgineVariant) => void;
}
export const VariantSelector: React.FC<VariantSelectorProps> = ({
  style,
  variant,
  setVariant,
}) => {
  return (
    <>
      <button
        style={style}
        className={variant === ImAIgineVariant.LR ? "active" : "inactive"}
        onClick={() => setVariant(ImAIgineVariant.LR)}
      >
        LR
      </button>
      <button
        style={style}
        className={variant === ImAIgineVariant.SR ? "active" : "inactive"}
        onClick={() => setVariant(ImAIgineVariant.SR)}
      >
        SR
      </button>
      <button
        style={style}
        className={variant === ImAIgineVariant.TI ? "active" : "inactive"}
        onClick={() => setVariant(ImAIgineVariant.TI)}
      >
        TI
      </button>
      <button
        style={style}
        className={variant === ImAIgineVariant.GX ? "active" : "inactive"}
        onClick={() => setVariant(ImAIgineVariant.GX)}
      >
        GX
      </button>
    </>
  );
};

export const FileCompare: React.FC = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const [lVariant, setLVariant] = useStorageState<ImAIgineVariant>(
    "fileCompare.lVariant",
    ImAIgineVariant.LR
  );
  const [rVariant, setRVariant] = useStorageState<ImAIgineVariant>(
    "fileCompare.rVariant",
    ImAIgineVariant.SR
  );
  const [compareFiles, setCompareFiles] = useState<CompareFiles | null>(null);

  useEffect(() => {
    async function getCompareFiles() {
      if (id) {
        if (
          !compareFiles ||
          !compareFiles.l ||
          compareFiles.l.variant !== lVariant ||
          !compareFiles.r ||
          compareFiles.r.variant !== rVariant
        ) {
          const baseFile = await authContext.db?.getImageById(id);
          if (!baseFile) {
            console.error("Failed to fetch base file", id);
            setCompareFiles({
              l: { variant: lVariant },
              r: { variant: lVariant },
            });
          } else {
            const lFile = await authContext.db?.getImageVariant(
              baseFile,
              lVariant
            );
            if (!lFile) {
              console.error("Failed to fetch lFile", baseFile.name, lVariant);
            }
            const rFile = await authContext.db?.getImageVariant(
              baseFile,
              rVariant
            );
            if (!rFile) {
              console.error("Failed to fetch rFile", baseFile.name, rVariant);
            }
            const l: CompareFileInfo = {
              file: lFile,
              scale: lVariant === ImAIgineVariant.LR ? 4 : 1,
              variant: lVariant,
            };
            const r: CompareFileInfo = {
              file: rFile,
              scale: rVariant === ImAIgineVariant.LR ? 4 : 1,
              variant: rVariant,
            };
            setCompareFiles({ l, r, name: baseFile.name });
          }
        }
      }
    }

    getCompareFiles();
  }, [id, compareFiles, setCompareFiles, authContext, lVariant, rVariant]);

  return (
    <div className={`FileCompare`}>
      {!!compareFiles && !!compareFiles.name ? (
        <>
          <div className="FileInfo">{compareFiles.name}</div>
          <div className="VariantPanel">
            <VariantSelector
              style={!compareFiles.l?.file ? { color: "darkred" } : {}}
              variant={lVariant}
              setVariant={setLVariant}
            />
            {" vs "}
            <VariantSelector
              style={!compareFiles.r?.file ? { color: "darkred" } : {}}
              variant={rVariant}
              setVariant={setRVariant}
            />
          </div>
          <div>
            {!!compareFiles.l?.file && !!compareFiles.r?.file ? (
              <CompareImage compareFiles={compareFiles} />
            ) : (
              <>
                <div className="Info">no suitable comparison image found</div>
                <LazyImage
                  className="FallbackImage"
                  alt="base"
                  src={authContext?.db?.getImageURL(id)}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <>loading</>
      )}
    </div>
  );
};
